import request from '@/utils/request'


// 查询用户-用户浏览记录列表
export function listHistory(query) {
  return request({
    url: '/user/history/list',
    method: 'get',
    params: query
  })
}

// 查询用户-用户浏览记录分页
export function pageHistory(query) {
  return request({
    url: '/user/history/page',
    method: 'get',
    params: query
  })
}

// 查询用户-用户浏览记录详细
export function getHistory(data) {
  return request({
    url: '/user/history/detail',
    method: 'get',
    params: data
  })
}

// 新增用户-用户浏览记录
export function addHistory(data) {
  return request({
    url: '/user/history/add',
    method: 'post',
    data: data
  })
}

// 修改用户-用户浏览记录
export function updateHistory(data) {
  return request({
    url: '/user/history/edit',
    method: 'post',
    data: data
  })
}

// 删除用户-用户浏览记录
export function delHistory(data) {
  return request({
    url: '/user/history/delete',
    method: 'post',
    data: data
  })
}
